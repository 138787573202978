.SideBarContainer {
  /* min-width: 240px; new set */
  width: auto;
  height: auto;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 32px 0px #72729f29;
}
.BandContainer {
  width: 100%;
  height: 64px;
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
}
.BrandLogo {
  width: 100%;
  height: 100%;
}
.BrandLogo .BrandLogoImg {
  width: 106px;
  height: 32px;
}
.BrandLogo .BrandLogoImgSmall {
  width: 48px;
  height: 32px;
}
.NavigationBarContainer {
  width: 100%;
  /* height: 920px; */
  height: calc(100% - 64px);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
}
.NavigationBar {
  width: auto;
  /* max-width: 200px;  new set*/
  height: 800px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.BottomTabContainer {
  width: 100%;
  height: auto;
}
.BottomTabContainer .BottomTab {
  width: 100%;
  height: auto;
  padding: 12px 16px 12px 16px;
  border-radius: 16px;
}
.NavTab {
  /* width: 200px;/new set */
  width: auto;
  height: 48px;
  border-radius: 16px;
  text-decoration: none;
  padding: 12px 16px 12px 16px;
  display: flex;
  gap: 8px;
  font-weight: 400;
  color: #52525b;
}
.NavTab :hover {
  cursor: pointer;
}
/* onclick navtab */
.navTabActive {
  background: #fafafa;
  text-decoration: none;
  font-weight: 500;
  color: #21296d;
}
.NavTab .Icon {
  position: relative;
  width: 20px;
  height: 20px;
  padding: 1.67px 2.08px 1.67px 2.08px;
}
.NavTab .Icon .svgIcon {
  position: absolute;
  width: 15.83px;
  height: 16.67px;
  top: 1.67px;
  left: 2.08px;
  /* border: 1px 0px 0px 0px;
    opacity: 0px; */
}
.NavTab .Text {
  width: 140px;
  height: 24px;
  font-family: Urbanist;
  text-decoration: none;
  font-size: 16px;
  font-weight: inherit;
  text-align: left;
  color: inherit;
}
.SubscriptionPlanCard-wrapper {
  position: relative;
  width: 200px;
  height: 160px;
  border-radius: 20px;
  border: 1px solid transparent;
  background-image: linear-gradient(white, white),
    conic-gradient(
      from 180deg at 50% 50%,
      #80e8ff -100.8deg,
      #7999ff 86.4deg,
      #ff8aed 172.8deg,
      #82e9ff 259.2deg,
      #7999ff 446.4deg
    );
  background-origin: border-box;
  background-clip: padding-box, border-box;
}
.SubscriptionPlanCardSmall-wrapper {
  position: relative;
  width: 52px;
  height: 52px;
  border-radius: 16px;
  border: 1px solid transparent;
  background-image: linear-gradient(white, white),
    conic-gradient(
      from 180deg at 50% 50%,
      #80e8ff -100.8deg,
      #7999ff 86.4deg,
      #ff8aed 172.8deg,
      #82e9ff 259.2deg,
      #7999ff 446.4deg
    );
  background-origin: border-box;
  background-clip: padding-box, border-box;
}
.SubscriptionPlanCard {
  position: absolute;
  inset: 0px;
  padding: 14px 16px 14px 16px;
  border: none;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-image: linear-gradient(45deg, #80e8ff62, #7998ff60, #ff8aed5d);
}
.SubscriptionPlanCardSmall {
  position: absolute;
  inset: 0px;
  padding: 14px;
  border: none;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-image: linear-gradient(45deg, #80e8ff62, #7998ff60, #ff8aed5d);
}
.SubscriptionPlanCardSmall .Icon {
  position: relative;
  width: 24px;
  height: 24px;
  /* padding: 2.58px 0px 2.58px 0px; */
}
.SubscriptionPlanCard .Icon {
  position: relative;
  width: 24px;
  height: 24px;
  padding: 2.58px 0px 2.58px 0px;
}
.SubscriptionPlanCard .Text {
  font-family: Urbanist;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #21296d;
}

.SvgIcon {
  position: absolute;
  width: 100%;
  height: 100%;
}
.sidebarActiveContainer {
  width: auto;
  height: auto;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 32px 0px #72729f29;
}
