.CircularProgressBar-Container{
    position: relative;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    border: none;
    z-index: 0;
}
.CircularProgressBar-Container::after{
    position: absolute;
    inset: 25px;
    content: "";
    border-radius: 50%;
    background-color: #FFFFFF;
    z-index: -1;
}
.Text-conatiner{
    position: absolute;
    width: fit-content;
    height: fit-content;
    z-index: 1;
}
.Text-conatiner .ScoreVal{
    width: fit-content;
    height: fit-content;
    font-size: 3.5vw;
    font-weight: 500;
    line-height: 3.75vw;
    color: #18181B;
    text-align: center;
}
.Text-conatiner .MaxScore{
    font-size: 1vw;
    font-weight: 400;
    line-height: 0.75vw;
    color: #52525B;
    text-align: center;
}