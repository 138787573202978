.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, #0e0f10, #222a2f); 
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(15px);
}

@keyframes background-pan {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.progress-container {
  width: 80%;
  max-width: 500px;
  height: 30px;
  background: #e0e0e0;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  transform-origin: left;
  animation: slide-in 1s ease-in-out;
}

@keyframes slide-in {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

.progress-bar {
  height: 100%;
  width: 0;
  background: linear-gradient(90deg, #4facfe, #00f2fe);
  border-radius: 15px;
  box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.2), 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: width 0.5s ease-in-out;
  position: relative;
  animation: pulsate-bar 2s infinite ease-in-out;
}

@keyframes pulsate-bar {
  0% {
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.2), 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  50% {
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5), 0 6px 12px rgba(0, 0, 0, 0.4);
  }
  100% {
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.2), 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  z-index: 2;
  animation: pulse-text 1.5s infinite;
}

@keyframes pulse-text {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

.status-text {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 1px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  animation: fade-in-out 3s infinite ease-in-out;
}

.status-text.fade-out {
  opacity: 0;
  transition: opacity 1s ease-out;
}

.progress-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: radial-gradient(circle, rgba(0, 242, 254, 0.3), transparent);
  animation: pulse-glow 1.5s infinite ease-in-out;
  z-index: 1;
}

@keyframes pulse-glow {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.spinner-icon {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}