.property_address_col{
    width: 40% !important;
}
.action_col{
    width: 20% !important;
}
.results_col{
    width: 20% !important;
}
.date_col{
    width: 20% !important;
}
