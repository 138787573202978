.SectionInspection{
    width: 100%;
    height: auto;
}
.InspectionCards{
    width: 100%;
    height: auto;
    gap: 24px;
    border-radius: 18px;
    background-color: #FFFFFF;
    border: none;
    outline: none;
    box-shadow: 0px 0px 32px 0px #72729F29;
    color: #18181B;
}
.InspectionCards .Heading{
    width: 100%;
    height: auto;
    font-size: 1.5vw;
    line-height: 1.75vw;
    font-weight: 600;
    color: inherit;
}
.InspectionCards .ContentSection{
    width: 100%;
    height: auto;
    padding: 14px 16px;
    border-radius: 12px;
    background-color: #FAFAFA;
}
.InspectionCards .ContentSection .Value{
    width: auto;
    height: auto;
    font-size: 1.5vw;
    line-height: 1.75vw;
    font-weight: 600;
    text-align: center;
    color: inherit;
}
.InspectionCards .ContentSection .Text{
    width: 100%;
    height: auto;
    font-size: 1vw;
    line-height: 1.25vw;
    font-weight: 500;
    text-align: center;
    color: inherit;
}