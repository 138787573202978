@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");
:root {
  --angle: 20deg;
}
/* MainDashBordContainer */

.MainDashBordContainer {
  width: 100%;
  height: auto;
  padding: 20px;
  background-color: #f4f4f5;
}
.MDBCWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.toggleSidebarBtn {
  position: absolute;
}
