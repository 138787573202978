.AgentsProfileSection{
    width: 100%;
    height: auto;
}
.APSCard{
    width: 100%;
    height: 280px;
    border-radius: 18px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 32px 0px #72729F29;
}
.APSCard .APCoverContainerTop .APCoverContainer{
    position: relative;
    width: 100%;
    height: 104px;
    background-size: cover;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
}
.APSCard .APCoverContainerTop{
    width: 100%;
    height: 156px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
}
.APSCard .APCoverContainer .Avatar{
    position: absolute;
    top: 52px;
    left: calc(50% - 52px);
    width: 104px;
    height: 104px;
    border-radius: 50%;
    background-color: #152640;
    overflow: hidden;
}
.APSCard .APCoverContainer .Avatar .AvTImg{
    width: 100%;
    height: 100%;
}
.APSCard .Heading{
    width: 100%;
    height: 2vw;
    font-size: 1.75vw;
    line-height: 2vw;
    font-weight: 600;
    color: #18181B;
    text-align: center;
}
.btnGroupele{
    width: 100%;
    height: auto;
}
.btnele{
    width: calc(50% - 4px);
    height: 48px;
    border-radius: 16px;
    background-color: #FAFAFA;
    border: 1px solid #E5E5E5;
}
.btnGroupele .btnele .Text{
    font-size: 1vw;
    line-height: 1.25vw;
    font-weight: 500;
    color: #18181B;
}
.CompanyDetailsCard{
    width: 100%;
    height: 280px;
    background-color: #FFFFFF;
    border-bottom: 1px solid  #EFEFF0;
    box-shadow: 0px 0px 32px 0px #72729F29;
    border-radius: 18px;
}
.CompanyDetailsCard .Heading{
    width: 100%;
    height: 1.5vw;
    line-height: 1.5vw;
    font-size: 1.25vw;
    font-weight: 600;
    color: #18181B;
}
.CompanyDetailsCard .CDCInfo{
    width: 100%;
    height: auto;
}
.CompanyDetailsCard .CDCInfo .top{
    width: 100%;
    height: auto;
}
.CompanyDetailsCard .CDCInfo .top .btnele{
    width: 100%;
    height: auto;
    padding: 16px 16px;
    border-radius: 12px;
    background-color: #FAFAFA;
    border: 1px solid #E5E5E5;
}
.CompanyDetailsCard .CDCInfo .top .text{
    font-size: 1vw;
    line-height: 1.25vw;
    font-weight: 500;
    color: #18181B;
}
.CompanyDetailsCard .CDCInfo .top .Icon{
    width: 20px;
    height: 20px;
}
.otherDepartmentCard{
    width: 100%;
    height: auto;
    background-color: #FFFFFF;
    border-radius: 18px;
    box-shadow: 0px 0px 32px 0px #72729F29;
    border-bottom: 1px solid #EFEFF0;

}
.otherDepartmentCard .Heading{
    width: 100%;
    height: 1.5vw;
    line-height: 1.5vw;
    font-size: 1.5vw;
    font-weight: 600;
    color: #18181B;
}
.otherDepartmentCard .MassageBtn{
    width: 100%;
    height: auto;
    border-radius: 12px;
    background-color: #21296D;
    padding: 12px 16px;
    outline: none;
    border: none;
    font-size: 1vw;
    font-weight: 500;
    line-height: 1.25vw;
    color: #FFFFFF;
    text-align: center;
}