.EPCSection{
    width: 100%;
    height: 832px;
}
.SideSection{
    width: 100%;
    height: 100%;
}
.SideSection .BtnGroupEle{
    width: 100%;
    height: 396px;
}
.SideSection .BtnGroupEle .TabBtnele{
    width: 100%;
    height: 190px;
    border: none;
    outline: none;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 32px 0px #72729F29;
    font-size: 1.25vw;
    line-height: 1.5vw;
    font-weight: 400;
    border-radius: 18px;
    text-align: center;
}
.SideSection .BtnGroupEle .TabBtnele.ActiveEleBtn{
    background-color: #21296D ;
    color: #FFFFFF;
    font-weight: 600;
}
.SideSection .heading{
    width: 100%;
    line-height: 1.5vw;
    height: 1.5vw;
    font-size: 1.25vw;
    font-weight: 600;
    color: #18181B;
}
.SideSection .ScoreCardEle{
    width: 100%;
    height: auto;
    background-color: #FFFFFF;
    border-radius: 18px;
    box-shadow: 0px 0px 32px 0px #72729F29;
}
.RiskAssessmentCardele{
    width: 100%;
    height: auto;
    background-color: #FFFFFF;
    border-radius: 18px;
    border: 1px solid  #E5E5E5;
    padding: 8px 12px;
}
.IconCaontainer{
    width: 75px;
    height: 80px;
    background-color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 0px 32px 0px #72729F29;
}
.ContentContainer{
    width: calc(100% - 75px - 8px);
    height: 100%;
}
.ContentContainer .Heading{
    width: 100%;
    height: 24px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #18181B;
}
.RiskIndicatorContainer{
    width: 100%;
    height: 46px;
    gap: 2px;
}
.RiskIndicator{
    width: 100%;
    height: 16px;
    border-radius: 8px;
    background: linear-gradient(90deg, #22C55E 0%, #FCD34D 50%, #EF4444 100%);
}
.RiskAssessmentCardele .ContentContainer .RiskIndicatorContainer .IndicatorContainer{
    position: relative;
    width: 100%;
    height: 10px;
    padding-right: 10px;
}
.RiskAssessmentCardele .ContentContainer .RiskIndicatorContainer .IndicatorContainer .indicator{
    position: absolute;
    width: 0px;
    height: 0px;
    border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 10px solid #27272A;
}
.RiskAssessmentCardele .ContentContainer .RiskIndicatorContainer .SubContainer{
    width: 100%;
    height: 34px;
    gap: 2px;
}
.RiskAssessmentCardele .ContentContainer .RiskIndicatorContainer .SubContainer .TextContainer{
    width: 100%;
    height: 16px;
}
.RiskAssessmentCardele .ContentContainer .RiskIndicatorContainer .SubContainer .TextContainer .Text{
    width: 33.33%;
    height: 16px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #52525B;
    text-align: center;
}
.RiskAssessmentCard{
    width: 100%;
    height: 396px;
    border-radius: 18px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 32px 0px #72729F29;
}
.RiskAssessmentCard .heading{
    width: 100%;
    height: 1.5vw;
    line-height: 1.5vw;
    font-size: 1.25vw;
    font-weight: 600;
    color: #18181B;
}
.ImageContainerUpload{
    width: 100%;
    height: 832px;
    background-color: #FFFFFF;
    border-radius: 18px;
    box-shadow: 0px 0px 32px 0px #72729F29;
}
.ImageUpload{
    width: 100%;
    height: 100%;
}
.PreviousEPCReport{
    width: 100%;
    height: auto;
}
.PreviousEPCReport .TableContainer{
    width: 100%;
    height: 360px;
    border-radius: 18px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 32px 0px #72729F29;
}
table.EPCTable {
    width: 100%;
    height: auto;
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 12px;
    border: 1px solid #E5E5E5;
    background-color: #FFFFFF;
}
table.EPCTable th:not(:last-child),
table.EPCTable td:not(:last-child) {
    border-right: 1px solid #E5E5E5;
}
   
   /* Apply a border to the bottom of all but the last row */
table.EPCTable>thead>tr:not(:last-child)>th,
table.EPCTable>thead>tr:not(:last-child)>td,
table.EPCTable>tbody>tr:not(:last-child)>th,
table.EPCTable>tbody>tr:not(:last-child)>td,
table.EPCTable>tfoot>tr:not(:last-child)>th,
table.EPCTable>tfoot>tr:not(:last-child)>td,
table.EPCTable>tr:not(:last-child)>td,
table.EPCTable>tr:not(:last-child)>th,
table.EPCTable>thead:not(:last-child),
table.EPCTable>tbody:not(:last-child),
table.EPCTable>tfoot:not(:last-child) {
 border-bottom: 1px solid hsl(0, 0%, 90%);
}
table.EPCTable>thead>tr:last-child>th{
    border-bottom: 1px solid hsl(0, 0%, 90%);
}
.col-head{
    height: 76px;
    padding: 10px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #21296D;
}
.ReportDateCol{
    width: 25%;
}
.Reportcol{
    width: 45%;
}
.EERcol{
    width: 25%;
}
.Actioncol{
    width: 12.5%;
}
.EERcol .EERrow-1{
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #E5E5E5;
}
.EERcol .EERrow-2{
    width: 100%;
    height: 36px;
}
.EERrow-itm1{
    border-right: 1px solid #E5E5E5;
    color:#71717A;
}
.EERrow-itm2{
    color:#71717A;
}
.datacol{
    height: 63px;
    padding: 10px;
    font-size: 1vw;
    line-height: 1.25vw;
    font-weight: 500;
    color: #18181B;
}
.datacol:nth-child(1){
    width: 25%;
}
.datacol:nth-child(2){
    width: 45%;
}
.datacol:nth-child(3){
    width: 12.5%;
}
.datacol:nth-child(4){
    width: 12.5%;
}
.datacol:nth-child(5){
    width: 12.5%;
}
.HeatingSystemMainContainer{
    width: 100%;
    height: 832px;
    border-radius: 18px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 32px 0px #72729F29;
}
.HeatingSystemMainContainer .HSImageUpload{
    width: 100%;
    height: 80%;
}
.HeatingSystemMainContainer .TopSection{
    width: 100%;
    height: auto;
}
.HeatingSystemMainContainer .TopSection .btnGroupItms{
    width: auto;
    height: auto;
}
.HeatingSystemMainContainer .TopSection .btnGroupItms .btnitm{
    width: 135px;
    height: 48px;
    border: none;
    outline: none;
    padding: 12px 16px;
    border-radius: 16px;
    background-color: #FAFAFA;
    border: 1px solid #E5E5E5;
    font-size: 0.9vw;
    line-height: 1vw;
    font-weight: 500;
    text-align: center;
    color: #18181B;
}
.HeatingSystemMainContainer .TopSection .btnGroupItms .btnitm.activeBtn{
    font-weight: 600;
    color: #FFFFFF;
    background-color: #21296D;
}


.IndicatorLoaderContainer{
    width: 305px;
    height: 42px;
    gap: 6px;
}
.IndicatorLoaderContainer .textContainer{
    width: 100%;
    height: 20px;
}
.IndicatorLoaderContainer  .textContainer .text{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #18181B;
}
.IndicatorLoaderContainer  .Indicator{
    position: relative;
    width: 100%;
    height: 16px;
    border-radius: 8px;
    background-color: #E5E5E5;
}
.IndicatorLoaderContainer  .Indicator-before{
    position: absolute;
    width: 0%;
    height: 16px;
    border-radius: 8px;
    background: linear-gradient(90deg, #21296D 0%, #5A74FA 100%);
}
.HeatingSystemMainContainer .Imageele{
    width: 480px;
    height: 480px;
}
.HeatingSystemMainContainer .CardContainer{
    width: 100%;
    height: auto;
}
.HeatingSystemMainContainer .CardContainer .Carditm{
    width: calc(50% - 8px);
    height: auto;
}
.ParentBluerContainer{
    position: relative;
}
.ChildBluerContainer{
    position: absolute;
    z-index: 1000;
    top: 0px;
    left:0px;
    width: 100%;
    height: 100%;
    background-color: #18181ba2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    padding: 0px;
}
.lockIconEle{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: none;
    background-color: #ffffff77;
}


.noDataCell{
    font-family: Urbanist;
    font-size: 40px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: hsl(240, 6%, 10%);
    padding: 199px 17px 32px 240px;
}

.lockIconEle {
    position: relative;
  }
  
  .lockText {
    position: absolute;
    bottom: -30px; /* Adjust this value to position text as needed */
    left: 50%;
    transform: translateX(-50%);
    color: #f6f6fe;
    font-weight: bold;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease;
  }
  
  .lockIconEle:hover .lockText {
    opacity: 1; /* Show text on hover */
  }
  

