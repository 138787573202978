.under-construction-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
    text-align: center;
}

.title {
    font-size: 2.5rem;
    color:  #21296D;
    margin-bottom: 20px;
}

.message {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 40px;
}

.construction-icon {
    font-size: 4rem;
    color: #ff6347;
}
