.PropertytimeLineBodyContentContainer{
    width: 100%;
    height: 832px;
    gap: 40px;
    border-radius: 12px;
    background-color: #FFFFFF;
    overflow-y: scroll;
    overflow-x: hidden;
}
::-webkit-scrollbar{
    display: none;
}
.PTCDateContentContainer{
    width: 100%;
    height: auto;
    font-family: Urbanist;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #18181B;
}
.DateHeading{
    width: 100%;
    height: 24px;
    font: inherit;
    color: inherit;
}
.DateTimelineContentContainer{
    width: 100%;
    height: auto;
    gap: 40px;
}
.PTCTimeContentContainer{
    position: relative;
    width: 100%;
    height: auto;
}
.PTCTimeContentContainer .PTCTimeContentHeading{
    width: 96px;
    height: 24px;
    /* //styleName: Heading Text/H6/Bold 16px; */
    font: inherit;
    color: #71717A;
}
.PTCTimeContentContainer .PTCTimeContentDot{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 0.5px solid  #7999FF;
    background-color: #EDF4FF;
    z-index: 100;
}
.PTCTimeContentContainer .PTCTimeContentText{
    max-width: calc(100% - 96px - 16px - 63px - 16px - 16px - 16px);
    height: auto;
    /* //styleName: Heading Text/H6/Bold 16px; */
    font: inherit;
    color: #71717A;
}
.PTCTimeContentContainer .PTCTimeContentChip{
    width: 63px;
    height: 24px;
    border-radius: 8px;
    border: 1px solid  #5A74FA;
    padding: 6px 8px 6px 8px;
    background-color: #EDF4FF;
}
.PTCTimeContentContainer .PTCTimeContentChip .Lable{
    width: auto;
    height: 16px;
    font: inherit;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #5A74FA;
}
.PTCTimeContentLine{
    position: absolute;
    top: calc(50% + 8px);
    left: calc(96px + 16px + 8px - 0.8px);
    height: calc(48px);
    border: 1px dotted  #D4D4D8;
    z-index: 99;
}
.PTCTimeContentContainer:last-child .PTCTimeContentLine{
    position: absolute;
    top: 20px;
    left: calc(96px + 16px + 8px - 0.8px);
    height: calc(88px);
    border: 1.5px dotted  #D4D4D8;
    z-index: 99;
}
.PTCDateContentContainer:last-child .DateTimelineContentContainer .PTCTimeContentContainer:last-child .PTCTimeContentLine{
    display: none;
}