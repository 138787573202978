.logout-option {
    margin-top: auto;
    cursor: pointer;
     width:50%;
    border-radius: 5px;
    /* transition: background-color 0.3s; */
  }
  

  
  /* Popup styling */
  .popup {
    top: 0;
    left: 0;
    position:relative;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .popup-buttons {
    display: flex;
    justify-content: space-around;
  
  }
  
  .confirm-btn, .cancel-btn {
    padding: 5px 12px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .confirm-btn {
    background-color: #22c55e;
    color: white;
  }
  
  .confirm-btn:hover {
    background-color: #16a34a;
  }
  
  .cancel-btn {
    background-color: #e63946;
    color: white;
  }
  
  .cancel-btn:hover {
    background-color: #ff7675;
  }