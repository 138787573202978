.MainContentSectionReportAgent {
  width: 100%;
  height: 832px;
  background-color: #ffffff;
  border-radius: 18px;
}
.ReportSection {
  width: 100%;
  height: 832px;
}
.ReportEPCTAbleContent {
  width: 100%;
  height: 100%;
}
.ReportProgressBarContainer {
  width: 100%;
  height: auto;
  border-radius: 18px;
  background-color: #ffffff;
  border: 1.5px solid #e5e5e5;
  box-shadow: 0px 0px 32px 0px #72729f29;
  gap: 20px;
}
.ReportProgressBarContainer .InfoContainer {
  width: calc(100% - 200px - 16px);
  height: auto;
}
.ReportProgressBarContainer .InfoContainer .Heading {
  width: 100%;
  height: auto;
  font-size: 1.5vw;
  line-height: 1.75vw;
  font-weight: 600;
  color: #18181b;
}
.ReportProgressBarContainer .InfoContainer .ReportCount {
  font-size: 1vw;
  line-height: 1.25vw;
  font-weight: 500;
  color: #18181b;
}
.ReportProgressBarContainer .InfoContainer .RatingContainer {
  width: 100%;
  height: auto;
}
.ReportProgressBarContainer
  .InfoContainer
  .RatingContainer
  .RatingContainerCol {
  width: calc(50% - 4px);
  height: calc(1vw + 20px);
  height: 100%;
  border-radius: 12px;
  padding: 10px;
  gap: 6px;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
}
.ReportProgressBarContainer
  .InfoContainer
  .RatingContainer
  .RatingContainerCol
  .Dot {
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
  border: none;
}
.ReportProgressBarContainer
  .InfoContainer
  .RatingContainer
  .RatingContainerCol
  .Text {
  width: auto;
  height: auto;
  font-size: 1vw;
  line-height: 1.25vw;
  font-weight: 500;
  color: #18181b;
}
/*TopSection*/
.TopSection {
  width: 100%;
  height: auto;
}
.TopSection .SearchContainetr {
  width: 30%;
  height: auto;
  border-radius: 8px;
  padding: 14px 16px;
  border: 1.5px solid #efeff0;
  background-color: #ffffff;
}
.TopSection .SearchContainetr .Icon {
  width: 20px;
  height: 20px;
}
.TopSection .SearchContainetr .InputSearch {
  width: calc(100% - 20px - 8px);
  height: 100%;
}
.TopSection .SearchContainetr .InputSearch .InputSearchTag {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  color: #71717a;
}
.TopSection .ButtonGroupContainer {
  width: calc(70% - 8px);
  height: auto;
}
.BtnEle {
  width: auto;
  height: auto;
  padding: 14px 16px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1.5px solid #efeff0;
  font-size: 1vw;
  line-height: 20px;
  color: #71717a;
  font-weight: 400;
}
