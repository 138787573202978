.property_address_col {
  width: 30% !important;
}
.result_col {
  width: 25% !important;
}

.date_col {
  width: 15% !important;
}
.expiry_col {
  width: 15% !important;
}
.action_col {
  width: 15% !important;
}
