.GalleryContainer{
    width: 100%;
    height: 100%;
    border-radius: 18px;
    gap: 16px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 32px 0px #72729F29;
}
.GalleryContainer .Heading{
    width: 100%;
    height: 1.5vw;
    font-size: 1.5vw;
    line-height: 1.5vw;
    font-weight: 600;
    color: #18181B;
}
.GalleryContainer .BodyContainer{
    width: 100%;
    height: auto;
    gap: 16px;
}
.GalleryContainer .BodyContainer .rowContainer{
    width: 100%;
    height: auto;
    gap: 16px
}
.GalleryContainer .BodyContainer .rowContainer .ImgContainer{
    position: relative;
    width: calc(50% - 8px);
    height: 135px;
    border-radius: 12px;
}
.GalleryContainer .BodyContainer .rowContainer .ImgContainer .Img{
    width: 100%;
    height: 100%;
}
.OverFlowContainer{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: #00000099;
    z-index: 100;
    line-height: 2.5vw;
    font-size: 2.25vw;
    color: #FFFFFF;
    font-weight: 600;
}
.BodyCompFM{
    width: 100%;
    height: auto;
    background-color: #EDF4FF;
}
.FloarImg{
    width: 100%;
    height: 280px;
}
.LivingRoomSection{
    width: 100%;
    height: auto;
    border-radius: 18px;
    background-color: #FFFFFF;
    border-bottom: 1px solid #EFEFF0;
    box-shadow: 0px 0px 32px 0px #72729F29;
}
.LivingRoomSection .TabSection{
    width: 100%;
    height: auto;
    gap: 14px
}
.LivingRoomSection .TabSection .TabBtn{
    width: fit-content;
    height: auto;
    padding: 10px 12px;
    border-radius: 16px;
    border: 1px solid #E5E5E5;
    font-size: 14px;
    line-height: 18px;
    color: #18181B;
    font-weight: 500;
    outline: none;
}
.TabBtnActive{
    color: #FFFFFF !important;
    background-color: #21296D;
}
.LRImageContainer{
    width: 300px;
    height: auto;
    border-radius: 18px;
    border: 1px solid #E5E5E5;
    background-color: #FFFFFF;
}
.LRImageContainer .LRImage{
    width: 100%;
    height: 300px;
    border-radius: 0px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
}
.LRImageContainer .bottomText{
    padding: 10px 16px;
    font-size: 1vw;
    list-style: 1.25vw;
    font-weight: 500;
    color: #18181B;
}