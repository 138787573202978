.dashboard-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
  padding: 20px;
  background-color: #f3f4f6;
  min-height: 100vh;
}
.Heading {
  font-size: 1.5vw;
  line-height: 1.75vw;
  color: #18181b;
  font-weight: 600;
}
.section {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.progressbar-container {
  grid-column: span 6;
}

.service-overview-container {
  grid-column: span 6;
}

.map-container {
  grid-column: span 7;
}

.task-history-container {
  grid-column: span 12;
}
.task-history-container table {
  border: 1px solid #e5e5e5;
}
.task-history-container table thead {
  padding: 10px;
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  font-family: Urbanist;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #21296d;
}
.Icon {
  position: relative;
  width: 20px;
  height: 20px;
}
.messages-container {
  grid-column: span 5;
}

/* Service Overview  styles */
.service-overview {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.IndicatorLoaderContainer {
  width: 100%;
  height: 60px;
}

.IndicatorLoaderContainer .textContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.IndicatorLoaderContainer .textContainer .text {
  font-size: 14px;
  font-weight: 600;
  color: #18181b;
}

.IndicatorWrapper {
  position: relative;
  width: 100%;
  height: 20px;
}

.Indicator {
  width: 100%;
  height: 16px;
  border-radius: 8px;
  background-color: #e5e5e5;
}

.Indicator-before {
  height: 100%;
  border-radius: 8px;
  background: linear-gradient(90deg, #21296d 0%, #5a74fa 100%);
}

.percentage-text {
  position: absolute;
  top: -20px;
  right: 0;
  font-size: 14px;
  font-weight: 600;
  color: #18181b;
}

/* Messages styles */
.messages-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.message-card {
  background-color: #f9fafb;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.message-card p {
  font-size: 14px;
  color: #4b5563;
  margin: 0;
}

.messages-container {
  /* padding: 20px;
    background-color: #f9fafb;
    border-radius: 12px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 0 auto;
    font-family: 'Arial', sans-serif; */
  color: black;
}

h3 {
  font-size: 1.2rem;
  color: #1e1e2d;
  margin-bottom: 15px;
}

.message-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  padding: 2px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.message-avatar {
  width: 48px;
  height: 48px;
  border-radius: 15%;
  object-fit: cover;
  margin-right: 12px;
}

.message-content {
  flex: 1;
}

.message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.message-name-role {
  display: flex;
  align-items: center;
}

.message-name {
  font-weight: bold;
  font-size: 0.9rem;
  color: #1e1e2d;
}

.message-role {
  font-size: 0.75rem;
  padding: 2px 8px;
  border-radius: 4px;
  margin-left: 8px;
  font-weight: 500;
}

.message-role.landlord {
  background-color: #e3f2fd;
  color: #1e88e5;
}

.message-role.tenant {
  background-color: #fff3e0;
  color: #fb8c00;
}

.message-role.contractor {
  background-color: #f3e5f5;
  color: #8e24aa;
}

.message-time-date {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 0.75rem;
  color: #a1a1b5;
}

.message-time {
  margin-bottom: 2px;
}

.message-text {
  margin: 0;
  font-size: 0.85rem;
  color: #6c6c80;
}

/* Map styles */

.map-section {
  width: 100%;
  height: 100px;
}

.map-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.map-title {
  font-size: 20px;
  font-weight: 600;
  color: #374151;
  margin: 0;
}

.search-bar {
  flex: 1;
}

.search-bar form {
  display: flex;
  align-items: center;
  width: 100%;
}

.search-bar input {
  padding: 10px;
  border: 1px solid #d1d5db;
  border-radius: 12px;
  font-size: 10px;
  width: 100%;
  max-width: 405px;
  margin-left: 85px;
}

.map-container {
  position: relative;
  /* filter: blur(5px); */
  width: 100%;
  height: auto;
  border-radius: 12px;
  overflow: inherit;
}

.map {
  height: 450px;
  border-radius: 12px;
}

.map-title {
  margin-bottom: 0px;
}

/* Task styles */

.task-history-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  overflow-y: scroll;
  overflow-x: hidden;
}

.task-history-heading {
  font-size: 24px;
  margin-bottom: 15px;
  color: #333;
}

.task-history-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.task-history-table th,
.task-history-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.task-history-table th {
  background-color: #ffffff;
  font-weight: bold;
}

.col-document {
  width: 20%;
}

.col-date {
  width: 15%;
}

.col-title {
  width: 25%;
}

.col-createdBy {
  width: 30%;
}

.col-action {
  width: 10%;
  text-align: center;
}

.action-button {
  font-weight: 600;
  color: #ffffff;
  background-color: #21296d;
  min-width: 100px;
  height: 34px;
  outline: none;
  gap: 16px;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  font-size: 0.75vw;
  line-height: 1vw;
  padding: 9px 20px;
  cursor: pointer;
}

.action-button:hover {
  background-color: #0056b3;
}

.task-history-table tr:hover {
  background-color: #f1f1f1;
}
.SideCircularContainer {
  width: calc(100% - 240px);
  height: auto;
}
.progressitm {
  width: 100%;
  height: auto;
  font-size: 1vw;
  line-height: 1.25vw;
  color: #52525b;
  font-weight: 400;
}
.itmdot {
  width: 18px;
  height: 14px;
  border-radius: 50%;
  border: none;
}
.itmText {
  width: calc(100% - 18px - 50px);
  height: auto;
  padding-right: 40px;
}
.itmTextVal {
  width: 46px;
  height: auto;
  padding-left: 10px;
  text-align: end;
}
.progressitm:nth-child(1) .itmdot {
  background-color: #157B3B;
}
.progressitm:nth-child(2) .itmdot {
  background-color: #22BF5B;
}
.progressitm:nth-child(3) .itmdot {
  background-color: #F5CD4B;
}
.progressitm:nth-child(4) .itmdot {
  background-color: #EE9A0B;
}
.progressitm:nth-child(5) .itmdot {
  background-color: #E74242;
}

/* Apply blur to the messages container */
.messages-container {
  position: relative; /* Needed for absolute positioning of lock icon */

  /* Always apply blur effect */
  overflow: hidden; /* Contain any overflowing content */
  z-index: 8;
  filter: blur(5px);
}
/* Lock Overlay Container */
.lock-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Semi-transparent white overlay */
  display: flex;
  justify-content: center; /* Center the lock icon horizontally */
  align-items: center; /* Center the lock icon vertically */
  z-index: 10; /* Ensure it appears above messages */
}

/* Styling for the lock icon */
.lock-icon {
  font-size: 60px; /* Size of the lock icon */
  color: black; /* Black color for the lock icon */
  cursor: pointer; /* Pointer cursor on hover */
  z-index: 20; /* Ensure it appears above the overlay */
}

/* Pop-up message displayed on hover over the lock icon */
.updated-message {
  position: absolute;
  top: 70%; /* Position below the lock icon */
  background-color: rgba(
    0,
    0,
    0,
    0.8
  ); /* Dark background with slight transparency */
  color: #fff; /* White text */
  padding: 10px 15px; /* Spacing around the text */
  border-radius: 5px; /* Rounded corners */
  font-weight: bold; /* Bold text */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Shadow effect */
  opacity: 0; /* Initially hidden */
  visibility: hidden; /* Initially hidden */
  transition: opacity 0.3s ease, visibility 0.3s ease; /* Smooth transition */
  z-index: 30; /* Above everything else */
}

/* Show the pop-up message when hovering over the lock icon */
.lock-icon:hover + .updated-message {
  opacity: 1; /* Fully visible */
  visibility: visible; /* Make it visible */
}
