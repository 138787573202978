#pdf-controls{
    display: flex;
}
.DocumentExitbtn{
    position: fixed;
    top: 10px;
    left: auto;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 50%;
    box-shadow: 0px 0px 32px 0px #72729F29;
}