.PropertiesSection {
  width: 100%;
  height: auto;
}
.CardContainer {
  width: 100%;
  height: auto;
  border-radius: 18px;
  background-color: #ffffff;
  box-shadow: 0px 0px 32px 0px #72729f29;
  gap: 20px;
}
.CardContainer .Heading {
  width: 100%;
  height: auto;
  line-height: 1.5vw;
  font-size: 1.25vw;
  color: #18181b;
  font-weight: 600;
  text-align: start;
}
.CardContainer .Value {
  width: 100%;
  height: auto;
  line-height: 2.25vw;
  font-size: 2vw;
  color: #21296d;
  font-weight: 600;
}
.CardContainer .ProgressContainer {
  width: 100%;
  height: auto;
}
/*LinerProgress*/
.CardContainer .ProgressContainer .LinerarPgrogress {
  position: relative;
  width: 100%;
  height: 16px;
  border-radius: 8px;
  background-color: #deebff;
}
.CardContainer .ProgressContainer .Loader {
  position: absolute;
  height: 100%;
  border-radius: 8px;
  background: linear-gradient(90deg, #21296d 0%, #5a74fa 100%);
}
/*LinerPgrogress-end*/
.CardContainer .ProgressContainer .Labels {
  width: 100%;
  height: auto;
}
.CardContainer .ProgressContainer .Labels .Dot {
  width: 12px;
  height: 12px;
  border: none;
  border-radius: 50%;
  background: #deebff;
}
.CardContainer .ProgressContainer .Labels .Text {
  width: calc(100% - 12px - 2vw - 8px - 8px);
  height: auto;
  font-size: 1.25vw;
  list-style: 1.5vw;
  color: #18181b;
  font-weight: 400;
  text-align: left;
}
.CardContainer .ProgressContainer .Labels .Value {
  width: 2vw;
  height: auto;
  font-size: 1.25vw;
  line-height: 1.5vw;
  color: #18181b;
  font-weight: 400;
  text-align: right;
}
.CardContainer .ProgressContainer .Labels .Dot.Active {
  background: linear-gradient(90deg, #21296d 0%, #5a74fa 100%);
}
.OpenRepairRegularMaintenance {
  width: 100%;
  height: 100%;
}
.CardContainer .OpenRepairRegularMaintenanceContent {
  width: 100%;
  height: calc(100% - 12px - 1.5vw);
  gap: 12px;
}
.CardContainer .OpenRepairRegularMaintenanceContent .Cards {
  flex: 1 1 0;
  border-radius: 12px;
  background-color: #e8fbff;
}
.CardContainer .OpenRepairRegularMaintenanceContent .Cards .Value {
  width: auto;
  height: auto;
  line-height: 2.25vw;
  font-size: 2vw;
  color: #00b0d5;
  font-weight: 600;
  text-align: center;
}
.CardContainer .OpenRepairRegularMaintenanceContent .Cards .Text {
  width: auto;
  height: auto;
  font-size: 1.25vw;
  list-style: 1.5vw;
  color: #18181b;
  font-weight: 400;
  text-align: center;
}
.CardContainer .OpenRepairRegularMaintenanceContent .Cards:nth-child(2) {
  background-color: #ffeffc;
}
.CardContainer .OpenRepairRegularMaintenanceContent .Cards:nth-child(3) {
  background-color: #f0fdf4;
}
.CardContainer .OpenRepairRegularMaintenanceContent .Cards:nth-child(2) .Value {
  color: #ae62a2;
}
.CardContainer .OpenRepairRegularMaintenanceContent .Cards:nth-child(3) .Value {
  color: #22c55e;
}
/*TableSection*/
.PropertiesTable {
  width: 100%;
  height: 560px;
  border-radius: 18px;
  background-color: #ffffff;
  box-shadow: 0px 0px 32px 0px #72729f29;
  gap: 16px;
}
.PropertiesTable .TopSection {
  width: 100%;
  height: auto;
}
.PropertiesTable .TopSection .SearchContainetr {
  width: 30%;
  height: auto;
  border-radius: 8px;
  padding: 14px 16px;
  border: 1.5px solid #efeff0;
  background-color: #ffffff;
}
.PropertiesTable .TopSection .SearchContainetr .Icon {
  width: 20px;
  height: 20px;
}
.PropertiesTable .TopSection .SearchContainetr .InputSearch {
  width: calc(100% - 20px - 8px);
  height: 100%;
}
.PropertiesTable .TopSection .SearchContainetr .InputSearch .InputSearchTag {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  color: #71717a;
}
.PropertiesTable .TopSection .ButtonGroupContainer {
  width: calc(70% - 8px);
  height: auto;
}
.BtnEle {
  width: auto;
  height: auto;
  padding: 14px 16px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1.5px solid #efeff0;
  font-size: 1vw;
  line-height: 20px;
  color: #71717a;
  font-weight: 400;
}

.propScoreWidth {
  width: 14% !important;
}

.complianceLevelWidth {
  width: 16.5% !important;
}

.custom-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1040;
}
/* SortModal.css */
.custom-modal .modal-dialog {
  max-width: 450px;
}

.custom-modal .modal-content {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.custom-modal .modal-header {
  background-color: #f0f2f5;
  border-bottom: 1px solid #e0e0e0;
  padding: 16px 24px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.custom-modal .modal-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.custom-modal .modal-body {
  padding: 20px 24px;
}

.custom-modal .description {
  font-size: 14px;
  color: #666;
  margin-bottom: 16px;
}

.custom-modal .form-check {
  margin-bottom: 12px;
}

.custom-modal .form-check-input {
  margin-right: 10px;
}

.custom-modal .form-check-label {
  font-size: 15px;
  color: #444;
}

.custom-modal .modal-footer {
  background-color: #f0f2f5;
  border-top: 1px solid #e0e0e0;
  padding: 15px 24px;
  display: flex;
  justify-content: flex-end;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.custom-modal .btn-primary {
  background-color: #4caf50;
  border-color: #4caf50;
  padding: 8px 20px;
  font-weight: 600;
}

.custom-modal .btn-outline-secondary {
  margin-right: 10px;
  color: #666;
  border-color: #ccc;
}

.btn-outline-secondary:hover {
  color: #fff;
}

.heading-text {
  font-size: 1.5vw !important;
}

.timeline-text {
  margin-right: auto;
  font-size: 16px;
  font-weight: 500;
}

.MTSechular {
  max-height: 520px !important;
  height: auto !important;
}
