.VacantDropDown {
  width: 100%;
  height: auto;
  padding: 17px 13px 19px 3px;
}
.Lable {
  line-height: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #18181b;
}
.selectOptionValueContainer {
  position: relative;
  width: fit-content;
  height: auto;
  padding: 12px, 8px;
  outline: none;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
  border: none;
  background-color: transparent;
}
.SelectOptionContainer {
  position: relative;
  width: fit-content;
  border-radius: 6px;
  background-color: #21296d;
  color: #ffffff;
  padding: 4px 10px;
}
.SelectOptionContainer:hover {
  cursor: pointer;
  background-color: #454c8f;
  color: #ffffff;
}
option {
  color: #21296d;
  font-weight: 500;
}
