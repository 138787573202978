.property_address_col{
    width: 40% !important;
}
.result_col{
    width: 20% !important;
}
.create_by_col{
    width: 20% !important;
}
.date_col{
    width: 20% !important;
}
