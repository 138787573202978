/* Spinner styles */
/* .spinner {
    display: inline-block;
    width: 60px;
    height: 60px;
    border: 6px solid rgba(195, 195, 195, 0.6);
    border-radius: 50%;
    border-top-color: #3498db;
    animation: spin 1s ease-in-out infinite;
    margin-right: 16px;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  } */
  
  /* Typing dots effect */
  .typing-dots span {
    font-size: 24px;
    animation: blink 1s infinite;
  }
  
  .typing-dots span:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .typing-dots span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  /* Container for spinner and message */
  .fetching-container {
    position: fixed; /* Make it an overlay */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; /* Full screen */
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(8px); /* Blur the background */
    background-color: rgba(255, 255, 255, 0.6); /* Slight white overlay */
    z-index: 9999; /* Ensure it stays on top */
    font-family: Arial, sans-serif;
    font-size: 32px;
    color: #333;
    text-align: center;
  }
  