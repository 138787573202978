.AvgTenancyScoreCardSection {
  width: 100%;
  height: auto;
  border-radius: 18px;
  background-color: #ffffff;
}
.ATSCInfoSection {
  width: 100%;
  height: 100%;
}
.ATSCInfoSection .TopSection {
  width: 100%;
  height: auto;
}
.ATSCInfoSection .TopSection .HeadingSection {
  width: 100%;
  height: auto;
}
.Heading {
  width: auto;
  height: 3vw;
  font-size: 2.5vw;
  font-weight: 500;
  line-height: 3vw;
  color: #18181b;
}
.ATSCInfoSection .TopSection .HeadingSection .Score {
  width: auto;
  height: 3vw;
  font-size: 2.5vw;
  font-weight: 500;
  line-height: 3vw;
  color: #18181b;
}
.ATSCInfoSection .TopSection .Text {
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  color: #71717a;
}
.ATSCInfoSection .BottomSection {
  width: 100%;
  height: auto;
}
.buttoncomp {
  width: 100%;
  height: auto;
  border: 1px solid #e5e5e5;
  background-color: #fafafa;
  border-radius: 12px;
  padding: 8px 12px 8px 12px;
  font-size: 16px;
  color: #18181b;
}
.buttoncomp.btntop {
  width: calc(50% - 4px);
  height: auto;
}
.TableTanent {
  width: 100%;
  height: 100%;
}
.TableTanent tr td {
  padding: 16px;
}
.TableTanent tr .header {
  font-size: 16px;
  font-weight: 600;
  color: #18181b;
}
.TableTanent tr .Name {
  font-size: 14px;
  font-weight: 500;
  color: #18181b;
}
.TableTanent tr .NameVal {
  font-size: 14px;
  font-weight: 500;
  color: #52525b;
  text-align: right;
}
table.rounded-corners {
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  background-color: #ffffff;
}

/* Apply a border to the right of all but the last column */
table.rounded-corners th:not(:last-child),
table.rounded-corners td:not(:last-child) {
  border-right: 1px solid #e5e5e5;
}

/* Apply a border to the bottom of all but the last row */
table.rounded-corners > thead > tr:not(:last-child) > th,
table.rounded-corners > thead > tr:not(:last-child) > td,
table.rounded-corners > tbody > tr:not(:last-child) > th,
table.rounded-corners > tbody > tr:not(:last-child) > td,
table.rounded-corners > tfoot > tr:not(:last-child) > th,
table.rounded-corners > tfoot > tr:not(:last-child) > td,
table.rounded-corners > tr:not(:last-child) > td,
table.rounded-corners > tr:not(:last-child) > th,
table.rounded-corners > thead:not(:last-child),
table.rounded-corners > tbody:not(:last-child),
table.rounded-corners > tfoot:not(:last-child) {
  border-bottom: 1px solid hsl(0, 0%, 90%);
}

.TPSProfileCard {
  position: relative;
  width: 100%;
  height: auto;
  background-color: #ffffff;
  border-radius: 18px;
  padding-bottom: 12px;
}
.TSpProfileTopSection {
  position: relative;
  width: 100%;
  height: 150px;
}
.TPSProfileCardBGProfileImg {
  width: 100%;
  height: 100px;
  background-size: cover;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}
.ProfileImgContyainer {
  position: absolute;
  left: calc(50% - 50px);
  top: 50px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  /* border: 2px solid #FFFFFF; */
  overflow: hidden;
}
.ProfileImgContyainer .ProfileImg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.TPSProfileCard .InfoContainer {
  width: auto;
  height: auto;
}
.TPSProfileCard .InfoContainer .Name {
  line-height: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #18181b;
  text-align: center;
}
.TPSProfileCard .InfoContainer .details {
  line-height: 16px;
  font-size: 12px;
  font-weight: 400;
  color: #18181b;
  text-align: center;
}
.TPSProfileCard .MessageBtn {
  width: 80%;
  height: auto;
  border-radius: 16px;
  border: none;
  outline: none;
  padding: 8px 12px 8px 12px;
  background-color: #21296d;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
}
.TPSGraphSection {
  width: 100%;
  height: auto;
  border-radius: 18px;
  background-color: #ffffff;
  box-shadow: 0px 0px 32px 0px #72729f29;
}
.TPSGraphSection .Heading {
  width: 100%;
  height: auto;
  font-size: 18px;
  font-weight: 600;
  color: #18181b;
  line-height: 24px;
}
.LineGraphSection {
  width: 100%;
  height: 400px;
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(10px);
}
/*create-toolip*/
.tooltipContainer {
  position: absolute;
  width: 180px;
  height: 140px;
  background-color: #ffffff;
  box-shadow: 0px 0px 32px 0px #72729f29;
  border-radius: 12px;
  transform: translate(-50%, 0);
  transition: all 0.1s ease;
}
