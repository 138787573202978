.SectionMaintenanceandRepair{
    width: 100%;
    height: auto;
}
.RMBtnEle{
    width: 100%;
    height: auto;
    gap: 24px;
    border-radius: 18px;
    background-color: #FFFFFF;
    border: none;
    outline: none;
    box-shadow: 0px 0px 32px 0px #72729F29;
    color: #18181B;
}
.RMBtnEle .Heading{
    width: 100%;
    height: auto;
    font-size: 1.5vw;
    line-height: 1.75vw;
    font-weight: 600;
    color: inherit;
}
.RMBtnEle .ContentSection{
    width: 100%;
    height: auto;
    padding: 14px 16px;
    border-radius: 12px;
    background-color: #FAFAFA;
}
.RMBtnEle .ContentSection .Value{
    width: auto;
    height: auto;
    font-size: 1.5vw;
    line-height: 1.75vw;
    font-weight: 600;
    text-align: center;
    color: inherit;
}
.RMBtnEle .ContentSection .Text{
    width: 100%;
    height: auto;
    font-size: 1vw;
    line-height: 1.25vw;
    font-weight: 500;
    text-align: center;
    color: inherit;
}
.RMBtnEle.Active{
    background-color: #21296D;
    color: #FFFFFF;
}
.RMBtnEle .ContentSection.Active{
    background-color: #181D4E;
}
/*RegularMaintance Table*/
.PropertiesTable{
    width: 100%;
    height: 560px;
    border-radius: 18px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 32px 0px #72729F29;
    gap: 16px;
}
.PropertiesTable .TopSection{
    width: 100%;
    height: auto;
}
.PropertiesTable .TopSection .SearchContainetr{
    width: 30%;
    height: auto;
    border-radius: 8px;
    padding: 14px 16px;
    border: 1.5px solid #EFEFF0;
    background-color: #FFFFFF;
}
.PropertiesTable .TopSection .SearchContainetr .Icon{
    width: 20px;
    height: 20px;
}
.PropertiesTable .TopSection .SearchContainetr .InputSearch{
    width: calc(100% - 20px - 8px);
    height: 100%;
}
.PropertiesTable .TopSection .SearchContainetr .InputSearch .InputSearchTag{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
    color: #71717A;
}
.PropertiesTable .TopSection .ButtonGroupContainer{
    width: calc(70% - 8px);
    height: auto;
}
.BtnEle{
    width: auto;
    height: auto;
    padding: 14px 16px;
    border-radius: 8px;
    background-color: #FFFFFF;
    border: 1.5px solid #EFEFF0;
    font-size: 1vw;
    line-height: 20px;
    color: #71717A;
    font-weight: 400;

}