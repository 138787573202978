.InviteContainer{
    width: 100%;
    height: 960px;
    margin-top: 5%;
}
.NameContainer{
    width: 100%;
    height: auto;
}
.lableText{
    width: 100%;
    height: auto;
    font-size: 16px;
    line-height: 20px;
    color: #18181B;
    font-weight: 500;
}
.inputfeild{
    width: 100%;
    height: auto;
    padding: 14px;
    border-radius: 12px;
    background-color: #FFFFFF;
    border: 1px solid var(--GreyScaleColors-Grey200, #E5E5E5);
    font-size: 14px;
    line-height: 16px;
    color: #18181B;
    font-weight: 400;
    outline: none;
}
.Heading{
    width: 100%;
    height: auto;
    text-align: center;
    font-weight: 600;
    line-height: 38px;
    color: #09090B;
}
.submitBtn{
    width: auto;
    height: auto;
    align-self: center;
    background-color: #21296D;
    border-radius: 12px;
    border: none;
    padding: 12px 32px;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    font-weight: 600;
}

.inputField{
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    padding-left: 10px;
}
