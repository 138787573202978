.gauge-container{
  width: 300px;
  height: auto;
  position: relative;
}
.gauge-container .radial-gauge svg{
  width: 100%;
  height: fit-content;
}
.pointerCircle{
  position: absolute;
  left: calc(50% - 65px);
  top: calc(24.5px + 115px - 65px);
  width: 130px;
  height: 130px;
  background-color: #FFFFFF;
  border-radius: 50%;
  box-shadow: 0px 0px 88.6px 0px #72729F29;
  box-shadow: 0px 0px 88.6px 0px #18151550;
}

.Rod{
  position: absolute;
  left: calc(50% - 10px);
  top: -20px;
  width: 20px;
  height: calc(100% + 40px);
  
}
.pointerCircle #PointerId{
  transform-origin: center;
  transition: all 1s linear;
}
.ArrowShape{
  width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 20px solid #FFFFFF;
}
.pointerCircle .Heading{
  width: auto;
  height: auto;
  font-size: 2vw;
  line-height: 2.25vw;
  font-weight: 600;
  color: #18181B;
  text-align: center;
}
.pointerCircle .LableText {
  width: auto;
  height: auto;
  font-size: 0.75vw;
  line-height: 1vw;
  font-weight: 400;
  color: #71717A;
  text-align: center;
}