/* Base style for scaling at different breakpoints */
.app-container {
  transform-origin: top left;
  overflow: hidden;
}

@media screen and (max-width: 1300px) {
  .app-container {
    width: 117.65%; /* Compensates for 0.85 scale */
    height: 117.65%;
    transform: scale(0.85);
  }
  #guageEleId {
    width: 58px !important;
  }
}

@media screen and (max-width: 1000px) {
  .app-container {
    width: 125%; /* Compensates for 0.8 scale */
    height: 125%;
    transform: scale(0.8);
  }
}

#root {
  background-color: #f4f4f5;
}
.action-button-property-eye {
  background-color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  margin: 0;
}
.maintenanceCompletedBtn {
  background-color: #4caf50; /* Green background for completed */
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  cursor: default;
}

.maintenanceStartedBtn {
  background-color: #007bff; /* Blue background for start */
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.maintenanceStartedBtn:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.maintenanceNeutralBtn {
  background-color: #f0f0f0; /* Light gray background for neutral status */
  color: #666; /* Dark gray text */
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  cursor: not-allowed; /* Indicate that the button is disabled */
}

.PropertyECIRDocumentColWidth {
  width: 25% !important;
}
.mapProperty-rounded-corner{
  border-radius: 0.5rem;
}
