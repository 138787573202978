.OpenReapairSection{
    width: 100%;
    height: 726px;
}

.ORSSBBtn{
    width: 100%;
    height: 126px;
    border-radius: 18px;
    padding: 16px;
    background-color:  #FFFFFF;
    box-shadow: 0px 0px 32px 0px #72729F29;
    border: none;
    outline: none;
    font-size: 18px;
    font-weight: 400;
    color: #52525B;
}

.ORSBBtn{
    width: 100%;
    height: 190px;
    border-radius: 18px;
    padding: 16px;
    background-color:  #FFFFFF;
    box-shadow: 0px 0px 32px 0px #72729F29;
    border: none;
    outline: none;
    font-size: 18px;
    font-weight: 400;
    color: #52525B;
}
.ORSSBBtnActive{
    background-color: #21296D;
    font-weight: 600;
    color: #FFFFFF;
}
.ORSGraphSection{
    width: 100%;
    height: auto;
    border-radius: 18px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 32px 0px #72729F29;
}
.ORSGraphSection .Heading{
    width: 100%;
    height: auto;
    line-height: 20px;
    font-size: 16px;
    font-weight: 600;
    color: #18181B;
}
.ORSMainContentSection{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: #FFFFFF;
    border-radius: 18px;
    box-shadow: 0px 0px 32px 0px #72729F29;
}
.ORSMainContentSection .popupConainer{
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
}
.ORSMainContentSection .popupConainer .BodySection{
    width: 250px;
    height: auto;
    padding: 10px;
    border-radius: 12px;
    border-top-right-radius: 0px;
    border: 1px solid  #E5E5E5;
    box-shadow: 0px 0px 32px 0px #72729F29;
    background-color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    color: #52525B;
    line-height: 16px;
}
.ORSMainContentSection .popupConainer .BodySection .detailes{
    width: 100%;
    height: auto;
    font-size: 14px;
    font-weight: 400;
    color: #52525B;
    line-height: 18px;
}
.ORSMainContentSection .popupConainer .BodySection .detailes .name{
    /* padding-right: 5px; */
    width: 45%;
    height: auto;
    
}
.donename{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}
.ORSMainContentSection .popupConainer .BodySection .detailes .value{
    /* padding-left: 5px; */
    font-weight: 500;
    color: #18181B;
    overflow-wrap: break-word;
}
.doneSpan{
    width: auto;
    height: auto;
    border: 1px solid #16A34A;
    background-color: #F0FDF4;
    border-radius: 6px;
    padding: 4px 6px 4px 6px;
    color: #16A34A;
}
.ORSMainContentSection .popupConainer .BodySection .popupImg{
    width: 95%;
    height: 120px;
    border-radius: 10px;
}
.ORSMainContentSection .ORFloarMapImg{
    width: 100%;
    height: 100%;
    border-radius: 18px;
}
.ORTableContainer{
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid  #E5E5E5;
}
.ORTable{
    width: 100%;
    height: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    
}
.tableCol{
    /* height: 35px; */
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #21296D;
}
.columndata{
    padding: 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #18181B;
}
.RepairDetailsColumn{
    width: 17.5%;
    
}
.CompletionDateColumn{
    width: 20%;
}
.SatusColumn{
    width: 12.5%;
}
.CompletionReportColumn{
    width: 30%;
}
.RepairCostColumn{
    width: 12.5%;
}
.ActionColumn{
    width: 10%;
}
.ORTable thead tr th:not(:last-child),
.ORTable tbody tr td:not(:last-child) {
    border-right: 1px solid #E5E5E5;
}
table.ORTable>thead>tr:not(:last-child)>th,
table.ORTable>thead>tr:not(:last-child)>td,
table.ORTable>tbody>tr:not(:last-child)>th,
table.ORTable>tbody>tr:not(:last-child)>td,
table.ORTable>tfoot>tr:not(:last-child)>th,
table.ORTable>tfoot>tr:not(:last-child)>td,
table.ORTable>tr:not(:last-child)>td,
table.ORTable>tr:not(:last-child)>th,
table.ORTable>thead:not(:last-child),
table.ORTable>tbody:not(:last-child),
table.ORTable>tfoot:not(:last-child){
    border-bottom: 1px solid #E5E5E5;
}
.ORTable tbody .tableRowContainer{
    width: 100%;
    height: auto;
}
table.ORTable>thead>tr>th{
    border-bottom: 1px solid #E5E5E5;
}
.StatusChip{
    width: auto;
    height: auto;
    padding: 4px 6px 4px 6px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
}


.noDataCell{
    font-family: Urbanist;
    font-size: 40px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #18181B;
    padding: 304px 17px 32px 290px;
}